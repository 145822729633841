.about-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
}

.about-inner-container {
    display: flex;
    width: 720px;
    margin: 10vh 0;
    flex-direction: row;
    align-items: flex-start;
}
.about-image {
    width: 100%;
    margin-right: 10px;
    justify-content: flex-end;
}
.about-image img {
    width: 100%;
}
.about-description {
    width: 100%;
    margin-left: 10px;
    text-align: start;
    font-weight: 200;
}

@media (max-width: 767px) {
    .about-inner-container {
        flex-direction: column;
        margin: 15px 0 0 0;
    }
    .about-image {
        margin: 0;
    }
    .about-description {
        margin: 0;
        padding: 1vh 4vw;
    }
}