.carousel {
    display: block;
    position: relative;
    width: 100%;
    height: 600px;
    overflow-x: hidden;
}
.carousel-controls {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 40px;
    z-index: 1;
    justify-content: space-between;
}
.control-left, .control-right {
    display: flex;
    width: 40px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.33);
    align-self: center;
    justify-content: center;
    align-items: center;
}
.left-icon, .right-icon {
    fill: white;
}
.carousel-images {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    transition: transform 0.3s ease;
}
.image-container {
    height: 100%;
    padding-left: 15px;
}
.image-container:first-child {
    padding-left: 25px;
}
.image-container .image {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.hide {
    visibility: hidden;
}

@media (max-width: 767px) {
    .carousel-controls {
        display: none;
    }
    .carousel {
        width: 100%;
        height: auto;
    }
    .carousel-images {
        position: relative;
        flex-direction: column;
        height: auto;
        padding: 0;
    }
    .image-container, .image-container:first-child {
        width: 100%;
        height: auto;
        padding: 15px 0 0 0;
    }
}