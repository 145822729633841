.navbar {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 80px;
}
.nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo {
    width: auto;
    height: 60px;
    padding: 2px 0;
}
.nav-options {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px;
    list-style-type: none;
}
.option {
    position: relative;
}
.option:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: black;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out;
}
.option:hover:before {
    visibility: visible;
    transform: scaleX(1);
}
.mobile-menu {
    display: block;
}

@media (max-width: 767px) {
    .navbar {
        padding: 0 10px;
    }
    .nav-options {
        display: flex;
        width: 100%;
        height: auto;
        position: absolute;
        top: -100%;
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;list-style-type: none;
        grid-gap: 0px;
    }
    .nav-options.active {
        background-color: white;
        top: 80px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding-left: 0px;
    }
    .option {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vw;
        padding: 30px 0;
    }
    .option:before {
        content: none;
    }
    .option:hover:before {
        transform: none;
    }
    .menu-icon {
        width: 30px;
        height: 30px;
    }
}