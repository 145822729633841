@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: black;
}
h1, h2 {
  font-weight: 800;
  color: black;
}
.app {
  font-family: Montserrat;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-flow: column;
  height: 100vh;
}

@media (max-width: 767px) {
  .app {
    height: auto;
  }
}