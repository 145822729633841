.contact-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
}
.contact-inner-container {
    display: flex;
    width: 720px;
    margin: 10vh 0;
    flex-direction: column;
}
.contact-inner-container p {
    font-weight: 200;
}

@media (max-width: 767px) {
    .contact-inner-container {
        padding: 0 4vw;
    }
}