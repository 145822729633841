.image {
    display: inline-block;
    height: 100%;
    width: auto;
}

@media (max-width: 767px) {
    .image {
        display: inline-block;
        width: 100%;
        height: auto;
    }
}