@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: black;
}
h1, h2 {
  font-weight: 800;
  color: black;
}
.app {
  font-family: Montserrat;
  font-weight: 400;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  height: 100vh;
}

@media (max-width: 767px) {
  .app {
    height: auto;
  }
}
.navbar {
    display: -webkit-flex;
    display: flex;
    background-color: white;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 40px;
    height: 80px;
}
.nav {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.logo {
    width: auto;
    height: 60px;
    padding: 2px 0;
}
.nav-options {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px;
    list-style-type: none;
}
.option {
    position: relative;
}
.option:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: black;
    visibility: hidden;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: all 0.2s ease-in-out;
}
.option:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}
.mobile-menu {
    display: block;
}

@media (max-width: 767px) {
    .navbar {
        padding: 0 10px;
    }
    .nav-options {
        display: -webkit-flex;
        display: flex;
        width: 100%;
        height: auto;
        position: absolute;
        top: -100%;
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;
        -webkit-flex-direction: column;
                flex-direction: column;list-style-type: none;
        grid-gap: 0px;
    }
    .nav-options.active {
        background-color: white;
        top: 80px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        -webkit-align-content: center;
                align-content: center;
        padding-left: 0px;
    }
    .option {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        height: 10vw;
        padding: 30px 0;
    }
    .option:before {
        content: none;
    }
    .option:hover:before {
        -webkit-transform: none;
                transform: none;
    }
    .menu-icon {
        width: 30px;
        height: 30px;
    }
}
.image {
    display: inline-block;
    height: 100%;
    width: auto;
}

@media (max-width: 767px) {
    .image {
        display: inline-block;
        width: 100%;
        height: auto;
    }
}
.carousel {
    display: block;
    position: relative;
    width: 100%;
    height: 600px;
    overflow-x: hidden;
}
.carousel-controls {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 40px;
    z-index: 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.control-left, .control-right {
    display: -webkit-flex;
    display: flex;
    width: 40px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.33);
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.left-icon, .right-icon {
    fill: white;
}
.carousel-images {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.image-container {
    height: 100%;
    padding-left: 15px;
}
.image-container:first-child {
    padding-left: 25px;
}
.image-container .image {
    -webkit-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
.hide {
    visibility: hidden;
}

@media (max-width: 767px) {
    .carousel-controls {
        display: none;
    }
    .carousel {
        width: 100%;
        height: auto;
    }
    .carousel-images {
        position: relative;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: auto;
        padding: 0;
    }
    .image-container, .image-container:first-child {
        width: 100%;
        height: auto;
        padding: 15px 0 0 0;
    }
}
.home-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}
.about-container {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.about-inner-container {
    display: -webkit-flex;
    display: flex;
    width: 720px;
    margin: 10vh 0;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
.about-image {
    width: 100%;
    margin-right: 10px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.about-image img {
    width: 100%;
}
.about-description {
    width: 100%;
    margin-left: 10px;
    text-align: start;
    font-weight: 200;
}

@media (max-width: 767px) {
    .about-inner-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 15px 0 0 0;
    }
    .about-image {
        margin: 0;
    }
    .about-description {
        margin: 0;
        padding: 1vh 4vw;
    }
}
.contact-container {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
.contact-inner-container {
    display: -webkit-flex;
    display: flex;
    width: 720px;
    margin: 10vh 0;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.contact-inner-container p {
    font-weight: 200;
}

@media (max-width: 767px) {
    .contact-inner-container {
        padding: 0 4vw;
    }
}
